import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import type { Session } from 'src/shared/session';

import type { UserInfo } from 'src/app/pages/petition/shared/types';
import { getViewerSignature } from 'src/app/shared/api/signature';

import { getNewPetitionPage } from './getNewPetitionPage';
import { getUserPetitionInfo } from './userPetitionInfo';

export async function getUserInfo(
	session: Session | undefined,
	slug: string,
	utilityContext: UtilityContext,
): Promise<UserInfo> {
	if (!session) {
		return {
			ownPetition: false,
			canEdit: false,
			signed: false,
			newPetitionPage: false,
		};
	}

	const userId = session.user?.id;
	if (!userId) {
		return {
			ownPetition: false,
			canEdit: false,
			signed: false,
			newPetitionPage: await getNewPetitionPage(utilityContext),
		};
	}

	const [signature, petitionInfo, isInNewPetitionPageVariant] = await Promise.all([
		getViewerSignature(slug, utilityContext),
		getUserPetitionInfo(slug, utilityContext),
		getNewPetitionPage(utilityContext),
	]);

	const ownPetition = petitionInfo.user.id === userId;
	const canEdit = petitionInfo.viewerCanEdit;

	return {
		ownPetition,
		canEdit,
		newPetitionPage: isInNewPetitionPageVariant,
		signed: !!signature,
	};
}
