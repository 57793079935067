import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import {
	PetitionPageUserInfo,
	type PetitionPageUserInfoQuery,
	type PetitionPageUserInfoQueryVariables,
} from './userPetitionInfo.graphql';

type PartialPetition = NonNullable<PetitionPageUserInfoQuery['petition']>;

export async function getUserPetitionInfo(
	slugOrId: string,
	{ gql: { fetch } }: UtilityContext,
): Promise<PartialPetition> {
	const { data } = await fetch<PetitionPageUserInfoQuery, PetitionPageUserInfoQueryVariables>({
		query: PetitionPageUserInfo,
		variables: { slugOrId },
		rejectOnError: false,
	});
	if (!data?.petition) {
		throw new Error('failed to retrieve petition info');
	}
	return data.petition;
}
